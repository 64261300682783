import styled from 'styled-components'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';

function Search() {
    const [input, setInput] = useState("");
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        navigate('/searched/' + input);

    }

    return (
        <FormStyle onSubmit={submitHandler}>
            <div>
                {/* icon for search  */}
                <FaSearch />
                <input onChange={(e) => { setInput(e.target.value) }}
                    type="text" value={input} placeholder="Try Indian, Thai etc." />
            </div>

        </FormStyle>
    )
}


const FormStyle = styled.form`
margin: 2rem 20rem;

div{
    position: relative;
    width: 90%;

}
input{
    border: none;
    background: linear-gradient(35deg, #662f2b, #9c544f);
    font-size: 1.5rem;
    color: white;
    padding: 1rem 3rem;
    border-radius: 1rem;
    outline: none;
    width: 100%;
}
svg{
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(100%, -50%);
    color: white;
}

`
export default Search