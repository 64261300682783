import Veggie from "../component/Veggie";
import Papular from "../component/Papular";
import React from 'react'

function Home() {

    return (
        <>

            <Veggie></Veggie>
            <Papular></Papular>
        </>
    )
}

export default Home